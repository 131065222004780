import React from "react";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import Divider from "../components/common/Divider";
import Layout from "../components/common/Layout";
import Meta from "../components/common/Meta";
import { Script } from "gatsby";

const NewsPage = () => {
  const meta = {
    title: "お問い合わせ｜（株）Digital Stacks",
    description:
      "製品やサービスなど、Digital Stacksに関する各種お問い合わせは、こちらのフォームにて承っております。",
    pathname: "/contact-jp/",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <Breadcrumbs path="/contact-jp" className="mt-4 mb-8" />
      <div className="">
        <h1 className="px-4 lg:px-[100px] font-sub font-bold text-4xl md:text-[40px]  mb-[32px] leading-[60px] text-dsc-title">
          お問い合わせ
        </h1>
        <div className="max-w-[700px] mx-auto">
          <div id="contact-form">
            <Script id={`freshworksform-${new Date().getTime()}`} dangerouslySetInnerHTML={{ __html: `
var sc = document.createElement('script');
sc.src = 'https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/b170d3d45fc67e68cd1ff68ea0121c15cd322938388911505fddf4549b6310c0/form.js';
sc.crossOrigin = 'anonymous';
sc.id = 'fs_b170d3d45fc67e68cd1ff68ea0121c15cd322938388911505fddf4549b6310c0';
document.getElementById('contact-form').appendChild(sc);

new MutationObserver(function(mutationsList, observer) {
  mutationsList.forEach(mutation => {
    if (mutation.addedNodes) {
      mutation.addedNodes.forEach(node => {
        var css = node.querySelector ? node.querySelector('link[href*="webforms.css"]') : null;
        if (css) {
          observer.disconnect();
          css.addEventListener('load', function() {
            setTimeout(function() {
              loop: for (var i = 0; i < document.styleSheets.length; i++) {
                var styleSheet = document.styleSheets[i];
                if (styleSheet.href && styleSheet.href.includes('webforms.css')) {
                  var rules = styleSheet.rules;
                  for (var j = 0; j < rules.length; j++) {
                    var rule = rules[j];
                    if (rule.selectorText && rule.selectorText.includes('.hidden,')) {
                      var cssText = rule.cssText.replace('.hidden,', '.fs-webform-container .hidden,');
                      styleSheet.deleteRule(j);
                      styleSheet.insertRule(cssText, j);
                      break loop;
                    }
                  }
                }
              }
            });
          });
        }
      });
    }
  });
}).observe(document.body, { childList:true, subtree: true });
          ` }} />
          </div>
          <p className="text-base text-dsc-body px-4"><a target="_blank" href="https://corp.digitalstacks.net/privacy-policy" className="text-dsc-link">個人情報保護方針</a>についてご確認の上、ご同意いただける場合は上記の項目にチェックを入れてご送信ください。</p>
        </div>
        <p className="text-dsc-body text-xs leading-base tracking-[0.03] text-center px-4 lg:px-[100px] lg:mt-1">
          ご利用のインターネット環境によりフォームが表示されない場合がございます。
          <br />
          入力フォームが表示されない場合は、メールアドレスへお問い合わせください。
          <br />
          <a href="mailto:info@digitalstacks.net">info@digitalstacks.net</a>
        </p>
        <Divider spacing={80} />
      </div>
    </Layout>
  );
};

export default NewsPage;
